import React,{useContext, useEffect, useState} from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Search, Bell, ChevronCompactDown,Translate } from "react-bootstrap-icons";
import { Input, Badge, Menu, Dropdown,Modal, Button as AntButton } from "antd";
import Utils from "helper/Utils";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../app/AuthProvider";
import hashlogo from "../assets/images/hash-logo.png";
import BluLogo from "assets/BleLogo";
import { useTranslation } from 'react-i18next';
import Notifications from "./Notifications";
import axios from "axios";

const Header = ({noPadd,hideExtraNav},props) => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  // const history = useHistory()
  
  const {authUser} = useContext(AuthContext);
  const [showSetup, setshowSetup] = useState(false)
  const [redirect, setredirect] = useState(null);
  const [redirectLogout, setredirectLogOut] = useState(false);
  const [searchTag, setsearchTag] = useState(null)
  const logout = ()=>{
    Utils.processAuthOut()
    // setredirectLogOut(true)
    window.location.reload();
  }
  const user = authUser;//Utils.getLocalInfo("userhash")
  
  useEffect(() => {
    
    
    return () => {
      
    }
  }, [authUser])
  const handleOk = () => {
    setshowSetup(false);
  };

  const handleCancel = () => {
    setshowSetup(false);
  };
  const changeLocale = (locale) =>{
    localStorage.setItem('appLocale',locale);

    window.location.reload();


}
  const usermenu =  (
    <Menu className="mt-0">
         <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("en")} >
      <span className="ml-3" >English</span>  
      </Menu.Item>
      <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("ja")} >
      <span className="ml-3" >Japanese</span>  
      </Menu.Item>
       <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("de")} >
      <span className="ml-3" >Deutsch</span>  
      </Menu.Item> <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("fr")} >
      <span className="ml-3" >Français</span>  
      </Menu.Item> 
       <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("hi")} >
      <span className="ml-3" >हिन्दी</span>  
      </Menu.Item>
    </Menu>
  );
  const profileMenu = (
    <Menu className="rounded-l">
      <Link to={user["role"] === "creator" && user["creatorProfileStep"] === 6?"/my/settings/profile":"/account-settings/profile"}><Menu.Item key="1">Account Settings</Menu.Item></Link>
      <Menu.Item key="2">Notifications</Menu.Item>
      <Menu.Item onClick={logout} key="3">Logout</Menu.Item>
    </Menu>
  );
  const searchon = (e) => {
    if (e.key === "Enter") {
      setsearchTag(e.target.value);
      // setredirect(true);
      
      // history.push("/search/top?q=" + e.target.value);
      navigate("/search/top?q=" + e.target.value);
    }
  };
 
  if (redirect) {
    
    setredirect(null)
    return navigate("/search/top?q=" + searchTag);//<Redirect to={"/search/top?q=" + searchTag} />;
    
  }
  const getApp = async() =>{
  
    try {
      const path = Utils.apiUrl("general/download/app") + "?";
      const response = await axios.get(path, {
        responseType: 'blob', // Important for handling binary data
        headers: {Authorization: `Bearer AQU5SPgizEExKHZAqdAv_j-5UhAHkvsysnYvpeXViw7uKx`}
      });
  
      // Create a Blob from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Create a link element to download the file
      const link = document.createElement('a');
      link.href = url;
  
      // Set the file name (optional)
      link.setAttribute('download', 'BluMe-App-1.1.8.apk'); // Replace with the desired file name
  
      // Programmatically click the link to trigger download
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Failed to download file');
    }
  }
  if(!user){
    return (<></>)
  }
  return (
    <>
    <div className={noPadd?"fixed w-full top-0 left-0  z-40 bg-hg shadow-sm":"fixed w-full top-0 left-0 p-3 z-40 bg-hg"}>
      
      <Navbar
        id="headerfixed"
        expand="lg"
        className={noPadd?"header-bar auth  w-full flex justify-around bg-white":"header-bar auth  w-full flex justify-around bg-white rounded-xl"}
      >
        <Navbar.Brand href={"/beta"}>
        <Link to={"/"}><span className="text-h text-2xl font-semibold flex items-center space-x-2">
           {/* <BluLogo /> */}
           <img src={hashlogo} className="w-8" />
            <span className="text-h">HashSaga</span>
          </span></Link> 
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Navbar className="text-center flex justify-center flex-1 justify-content-center">
            {!hideExtraNav && <span className="w-1/2 ">
              <Input
                type="text"
                placeholder={t("home_search_placeholder")}
                className="rounded-xl focus:shadow-none" 
                onKeyPress={(e) => searchon(e)}
                prefix={<Search color="#0079FF" className="mr-2" />}
              />
            </span>}
          </Navbar>
          <Nav className="mr-right">
            {!hideExtraNav && <Nav.Item>
              {user["role"] === "follower" && user["creatorProfileStep"] < 6 && 
              <Link to="/creator/edit/1"><Button
                className="rounded-xl focus:shadow-none"
                variant="outline-primary"
              >
                Become a Creator
              </Button></Link>}
              {user["role"] === "creator" && user["creatorProfileStep"] < 6 && 
              <Link to="/creator/edit/1"><Button
                className="rounded-xl focus:shadow-none"
                variant="outline-primary"
              >
                Complete Setup
              </Button></Link>}
              {/* {user["creatorProfileStep"] === 5 && 
              <Link to="/creator/home"><Button
                className="rounded-xl focus:shadow-none"
                variant="outline-primary"
              >
                Creator View
              </Button></Link>} */}
              {user["role"] === "creator" && user["creatorProfileStep"] === 6 &&
              <Link to="/creator/home"><Button
                className="rounded-xl focus:shadow-none"
                variant="outline-primary"
              >
                Creator View
              </Button></Link>}
            </Nav.Item>}
            <Nav.Item className="px-4 cursor-pointer">
              <Notifications user={user} />
            </Nav.Item>
            <Nav.Item className="pr-4 visible">
              <Dropdown
                overlay={profileMenu}
                placement="bottomRight"
                trigger="click"
                className="cursor-pointer"
              >
                <div className="flex items-center space-x-2">
                  <span className="head-notificationsBox flex items-center justify-center text-white text-xl bg-blue-500">
                    {user.profilePicture ? 
                    <img alt={user.name} src={user.profilePicture } />
                      :user.name[0]
                  }
                  </span>
                  <span className="text-h font-semibold ">{user.name}</span>
                  <span className="text-h">
                    <ChevronCompactDown />
                  </span>
                </div>
              </Dropdown>
            </Nav.Item>
            <Nav.Item><Dropdown overlay={usermenu} placement="bottomRight" trigger={['click']}>
        {/* <User color="#ffffff" /> */}
        <div className='flex bg-light rounded-full items-center justify-center w-8 h-8' ><Translate /></div>
        </Dropdown> </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className='fixed bottom-0 w-full left-0 p-3 text-center bg-gray-200 visible md:hidden '>
  <Button onClick={getApp} className='rounded mr-3 bg-hs-blue  border-hs-dark' type='primary' >Download BluMe App</Button>
  </div>
    </div>
    <Modal title={""} footer={null} visible={showSetup}  closable={false} bodyStyle={{"paddingBottom":".5rem"}} maskStyle={{"backgroundColor":"#000000e3"}}>
    <p>It's fantastic to have you on board. Thank you for registering! </p>
    <p>Complete creator setup with minmum steps.</p>
    <p className="py-2 space-x-3">
    <Link  to="/creator/edit/1" >
    <AntButton type="primary" className="rounded-md bg-red-1 px-4 border-0">Complete Setup</AntButton></Link>
    <Link  to="/" ><AntButton className="rounded-md" >I will do later</AntButton></Link>
    </p>
  </Modal>
 </>
  );
};

export default Header;
