import React from 'react'

import { useTranslation } from 'react-i18next';
import { Col } from 'antd';

const HomeSectionThree = () => {
  const { t } = useTranslation();

    return (
        <Col className="mx-auto home-bg text-white " xs={24} sm={24} md={24} lg={24} xl={24}>
           <div className=" w-full p-3 pb-5 !md:pb-3">
               <div>#HASHSAGA - <span className=' font-normal text-xs'>
                {t("copyrighttext")}
                </span></div>
               
               <div className="flex flex-col pt-3 font-light">
                {/* <span className="uppercase font-light text-sm">Services</span>
                <span className="pt-2"></span>
                <span className="pt-3 text-xs">Buy Creators</span>
                <span className="pt-3 text-xs">Buy Post Views</span>
                <span className="pt-3 text-xs">Buy Video Views</span> */}
               </div>
               {/* <div className="flex flex-col pt-3 font-light">
                <span className="uppercase font-light text-sm">About</span>
                <span className="pt-2"></span>
                <Link className="pt-1" to={"/about-us"}><span className="text-xs text-white">About Us</span></Link>
                <Link className="pt-1" to={"/faqs"}><span className=" text-xs text-white">FAQ</span></Link>
                <Link className="pt-1" to={"/about-us"}><span className="text-xs text-white">Blog</span></Link>
                <Link className="pt-1" to={"/why-us"}><span className="text-xs text-white">Why Us?</span></Link>
                <Link className="pt-1" to={"/terms-services"}><span className="text-xs text-white">Terms of Service</span></Link>
                <Link className="pt-1" to={"/contact-us"}><span className="text-xs text-white">Contact Us</span></Link>
               </div> */}
               {/* <div className="flex flex-col pt-3">
                <span className="uppercase font-light text-sm">Contact Us</span>
                <span className="pt-2"></span>
                <span className="pt-3 text-xs">Email us: <a href='mailto:support@hashsaga.com' className='text-hs-light'>support@hashsaga.com</a></span>
                <span className="pt-3 text-xs">Write for us: <a href='mailto:writeforus@hashsaga.com' className='text-hs-light'>writeforus@hash.com</a></span>
                <span className="py-6"></span>
                <span><button className="bg-blue-1 p-3 rounded-md">Get Free Trail</button></span>
               </div> */}

            </div> 
        </Col>
    )
}

export default HomeSectionThree
