import { Button, List, Menu } from "antd";
import Utils from "helper/Utils";
import React, { useEffect, useState } from "react";
import {
  BookmarkHeart,
  CardChecklist,
  ChatRightText,
  Hash,
  Heart,
  House,
  ListStars,
  Newspaper,
  People,
  PeopleFill,
  Share,
  Shop,
  ShopWindow,
  Sliders,
  Trophy,
} from "react-bootstrap-icons";
import {ShareSocial} from 'react-share-social' 
import { Modal } from "react-bootstrap";

import { BrowserRouter, Link,  useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const SidebarLeft = (props) => {
  const navigate = useNavigate()
  const brParams = useLocation();
  const user = Utils.getLocalInfo("userhash");
  const [selectedKeys, setselectedKeys] = useState(["/"])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
      
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  useEffect(() => {
   
    setselectedKeys(brParams.pathname)
    return () => {
      
    }
  }, [])
  

  const clickedMenu = (e) => {
    
    if(e.key === "/hashmine"){
      if(user.role === "follower"){
       navigate(e.key);
      }
      else{
       navigate('/creator/hashmine')
      }
    }
    else if(e.key === "/download"){
     return;
    }
    else{
     navigate(e.key);
    }
    
  };
  const getApp = async() =>{
  
    try {
      const path = Utils.apiUrl("general/download/app") + "?";
      const response = await axios.get(path, {
        responseType: 'blob', // Important for handling binary data
        headers: {Authorization: `Bearer AQU5SPgizEExKHZAqdAv_j-5UhAHkvsysnYvpeXViw7uKx`}
      });
  
      // Create a Blob from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Create a link element to download the file
      const link = document.createElement('a');
      link.href = url;
  
      // Set the file name (optional)
      link.setAttribute('download', 'BluMe-App-1.1.8.apk'); // Replace with the desired file name
  
      // Programmatically click the link to trigger download
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Failed to download file');
    }
  }
  return (<>
   
    <div className="">
      <div section-1 className="rounded-xl bg-white p-2">
        <Menu className="border-0" selectedKeys={selectedKeys} onSelect={clickedMenu}>
          <Menu.Item
            key={"/"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<House size={18} />}
          >
            <span>Home</span>
          </Menu.Item>
          <Menu.Item
            key={"/bookmarks/all"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<BookmarkHeart size={18} />}
          >
            Bookmarks
          </Menu.Item>
          <Menu.Item
            key={"/groups/list"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<PeopleFill size={18} />}
          >
            Groups
          </Menu.Item>
          <Menu.Item
            key={"/hashmine"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Hash size={18} />}
          >
            Hashmine
          </Menu.Item>
          {/* <Menu.Item
            key={"/feeds"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<CardChecklist size={18} />}
          >
            <span>Feeds</span>
          </Menu.Item> */}
          {/* <Menu.Item
            key={"/marketplace"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<ShopWindow size={18} />}
          >
            Marketplace
          </Menu.Item>
          <Menu.Item
            key={"/social-shop"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Shop size={18} />}
          >
            Social Shop
          </Menu.Item> */}
            <Menu.Item
              key={"/news"}
              className="text-hl flex flex-row items-center border-l-2 "
              icon={<Newspaper size={18} />}
            >
              News
            </Menu.Item>
          
          {/* <Menu.Item
            key={"/subscriptions"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<CardChecklist size={18} />}
          >
            Subscriptions
          </Menu.Item> */}
          {/* <Menu.Item
            key={"/settings"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Sliders size={18} />}
          >
            More
          </Menu.Item> */}
           <Menu.Item className="hidden md:block" key={"/download"}>
          <Button onClick={getApp}  className='rounded mr-3 bg-hs-blue  border-hs-dark' type='primary' >Download BluMe App</Button>
          </Menu.Item>
        </Menu>
      </div>
      <div section-2 className="rounded-xl bg-white mt-10 overflow-hidden">
        <div profile-box className="home-bg h-20 d-flex flex-row items-center">
          <div
            profile-image
            className="h-20 w-16 bg-center bg-cover"
            style={{
              backgroundImage:
                `url("${user.profilePicture}")`,
            }}
          ></div>
          <span className="text-white pl-2 ml-2 flex-grow">
            {user.name}
            <br></br>
            <span className="text-xs opacity-75">{`@${user.userName}`}</span>
          </span>
          <span onClick={()=>showModal()} className="text-white self-start flex w-10 h-10 cursor-pointer items-center justify-center">
            <Share size={16} />
          </span>
        </div>
        <div className="py-3"></div>
        <div className="flex flex-row justify-around ">
          <div className="flex flex-col items-center space-y-2">
            <span className="text-hr">
              <Trophy size={18} />
            </span><div className="py-1"></div>
            <Link to={`/account-settings/activities`}><Button type="primary">My Activities</Button></Link>
            <Link to={`/account-settings/wallet`}><Button type="primary">My Wallet</Button></Link>
            {/* <ShareSocial 
     url ="url_to_share.com"
     socialTypes={['facebook','twitter','reddit','linkedin']}
   /> */}
            {/* <span className="py-1 opacity-50">My Rewards</span>
            <span className="font-bold text-xl ">1245</span> */}
          </div>
          {/* <div className="flex flex-col items-center">
            <span className="text-hr">
              <People size={18} />
            </span>
            <span className="py-1 opacity-50">Following</span>
            <span className="font-bold text-xl ">245</span>
          </div> */}
        </div>
        <div className="py-3"></div>
        {/* <div className="text-h p-2 px-3 flex items-center text-h justify-between">
          <span className="text-sm">Who viewed your profile</span>
          <span className="font-bold text-xl text-hb ml-2">205</span>
        </div>
        <div className="py-1"></div>
        <div className="text-h p-2 px-3 flex items-center text-h justify-between">
          <span className="text-sm">Views of your post</span>
          <span className="font-bold text-xl text-hb ml-2">1,205</span>
        </div> */}
        <div className="py-3"></div>
      </div>
    </div>
    <Modal show={isModalOpen} onHide={handleCancel} dialogClassName="modal-25w">
    <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body className="p-0">
   <p><ShareSocial 
     url ={`https://hashsaga.com/${user.userName}`}
     style={{copyContainer: {
      border: 'none',
      background: 'rgb(0,0,0,0.7)',
      fontFamily:"Poppins",
      alignItems:'center'
    },}}
     socialTypes={['facebook','twitter','whatsapp','linkedin','telegram','email']}
     onSocialButtonClicked={ data => handleCancel} 
   /></p>
   </Modal.Body>
 </Modal>
 </>
  );
};

export default SidebarLeft;
